import apiService from "../../../shared/services/api-services/api-service";
import jwtService from "../../../shared/services/jwt-services/jwt-service";

class LoginService {

    Url = process.env.REACT_APP_BACKEND_AUTH_URL;
  
    async signIn(user) {
        user["deviceInfo"] = jwtService.getDeviceInfo();
        return apiService.post(`${this.Url}auth/users/authenticate`, user);
    }

    async signOut() {
        const device = {"deviceId" : jwtService.getDeviceId() };
        return apiService.post(`${this.Url}umm/users/devices/signout`, device);
    }

    async verifyUser() {
        return apiService.get(`${this.Url}auth/users/verify`);
    }

    async verifyCode(user) {
        return apiService.post(`${this.Url}umm/users/confirm`,user);
    }
}

export default new LoginService();