import JwtService from "../jwt-services/jwt-service";
import axios from 'axios';

class RefreshTokenService {

    constructor() {
        this.Url = process.env.REACT_APP_BACKEND_AUTH_URL;
    }

    async refreshToken () {
        return new Promise((resolve) => {
            try {
                const refreshToken = JwtService.getRefreshToken();
                const clientId = JwtService.getClientId();
                const deviceInfo = JwtService.setDeviceInfo();
                
                if (refreshToken && clientId) {
                    const reqBody = {
                        "refreshToken": refreshToken,
                        "clientId": clientId,
                        "clientSecret": "",
                        "deviceInfo" : deviceInfo
                    }
                    axios.post(`${this.Url}auth/users/authenticate`, reqBody,
                        {
                            headers: {
                                'Content-Type': 'application/json',
                                'Accept': 'application/json',
                            }
                        }).then(authRes => {
                            //console.log(authRes);
                            if (authRes && authRes.status === 200) {
                                console.log(authRes);
                                var responseData = authRes.data.data;
                                JwtService.setAccessToken(responseData.accessToken, clientId);
                                resolve(responseData.accessToken);
                            } else {
                                console.log(authRes);
                                JwtService.removeTokens();
                                window.location.reload(true);
                                resolve(false);
                            }
                        }).catch(err => {
                            console.log(err);
                            JwtService.removeTokens();
                            window.location.reload(true);
                            resolve(false);
                        });
                } else {
                    JwtService.removeTokens();
                    window.location.reload(true);
                    resolve(false);
                }
            } catch (ex) {
                //console.log(ex);
                JwtService.removeTokens()
                resolve(false);
            }
        });
    }
}

export default new RefreshTokenService();