import { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { PropagateLoader } from "react-spinners";
import TenantContext from "../../protected-components/contexts/TenantCotext";
import UserContext from "../../protected-components/contexts/UserContext";
import LoadingPage from "../../shared/components/LoadingPage";
import JWTService from "../../shared/services/jwt-services/jwt-service";
import loginService from "../login/login-services/login-service";
import forgetPasswordService from "./forget-password-services/forget-password-service";
import Validators from '../../shared/validators/validator';
import { Link } from "react-router-dom";
import PasswordField from "../../shared/components/PasswordField";

function ForgetPassword(props) {

    const history = useHistory();
    const location = useLocation();
    const [User, setUser] = useContext(UserContext);
    const [Tenant, setTenant] = useContext(TenantContext);

    const [forgetButton, setForgetButton] = useState('Send Code');
    const [dynamicStyle, setDynamicStyle] = useState('#FFFFFF')
    const [loading, setLoading] = useState(true)

    let loadingSpinner = <PropagateLoader
        css={'left: 50%; right : 50%; top: -5px'}
        size={10}
        color={"#FFFFFF"}
        loading={true}
    />

    if (Tenant.colors !== null && dynamicStyle === '#FFFFFF') {
        setDynamicStyle(Tenant.colors.background);
    }

    const [forgotUser, setforgotUser] = useState(
        {
            email: "",
            code: "",
            newPassword: "",
            comfirmPassword: "",
            newPasswordShow: false,
            showNewComfirmPassword: false,
            passwordStrength: false,
            codeTextbox: false,
            errorMessage: "",
            resetCompleted: false,
        }
    );

    useEffect(() => {

        if (JWTService.getAccessToken() !== null) {
            if (location.pathname !== "/") {
                history.push(`/${props.basePath}`);
            }
            //setLoading(false);
        } else {
            setLoading(true);
            if (JWTService.getRefreshToken() !== null && Tenant.clientId !== null) {
                loginService.verifyUser().then(user => {
                    if (location.pathname !== "/") {
                        history.push(`/${props.basePath}`);
                    }
                }).catch(err => {
                    setLoading(false);
                    setUser(null);
                });
            } else {
                setLoading(false);
                setUser(null);
            }
        }
    }, []);

    const setForgetPasswordError = (message) => {
        setforgotUser({ ...forgotUser, errorMessage: message });
    }

    const OnSubmit = (event) => {
        event.preventDefault();
        setForgetPasswordError("");
        setForgetButton(loadingSpinner);
        let request =
        {
            "clientId": Tenant.clientId,
            "clientSecret": "",
            "user": {}
        };
        if (!Validators.isEmptyOrNullOrUndefined(forgotUser.email) && Validators.isEmptyOrNullOrUndefined(forgotUser.code)) {

            request.user.email = forgotUser.email;
            if (!Validators.isEmptyOrNullOrUndefined(forgotUser.email)) {
                forgetPasswordService.forgetPassword(request).then(response => {
                    //console.log(response);
                    if (response.data.messageKey === "FORGET_PASSWORD_EMAIL_SEND") {
                        setLoading(false);
                        setForgetButton('Change Password');
                        setforgotUser({ ...forgotUser, codeTextbox: true });
                    } else {
                        setLoading(false);
                        setForgetButton('Send Code');
                        setForgetPasswordError("Email send failed");
                    }
                }).catch(response => {
                    setLoading(false);
                    setForgetButton('Send Code');
                    //console.log(response.response.data.message);
                    setForgetPasswordError("Invalid email");
                });
            }
        } else if (!Validators.isEmptyOrNullOrUndefined(forgotUser.email) && !Validators.isEmptyOrNullOrUndefined(forgotUser.code) &&
            !Validators.isEmptyOrNullOrUndefined(forgotUser.newPassword) && !Validators.isEmptyOrNullOrUndefined(forgotUser.confirmNewPassword)) {

            //console.log(forgotUser.code.length);
            if (forgotUser.code.length < 6) {
                setLoading(false);
                setForgetButton('Reset Password');
                setForgetPasswordError("Invalid code");
            } else if (forgotUser.passwordStrength == false) {
                setLoading(false);
                setForgetButton('Reset Password');
                setForgetPasswordError("Please check your new password strength");
            } else {
                if (forgotUser.newPassword == forgotUser.confirmNewPassword) {
                    request.user.email = forgotUser.email;
                    request.user.code = forgotUser.code;
                    request.user.newPassword = forgotUser.newPassword;
                    forgetPasswordService.verifyCode(request).then(response => {
                        if (response.data.messageKey === "PASSWORD_CHANGED") {
                            setforgotUser({ ...forgotUser, resetCompleted: true, errorMessage: "" });
                        } else {
                            setLoading(false);
                            setForgetButton('Reset Password');
                            setForgetPasswordError("Verification failed");
                        }
                    }).catch(response => {
                        setLoading(false);
                        //console.log(response);
                        setForgetButton('Reset Password');
                        setForgetPasswordError(response.response.data.message);
                    });
                } else {
                    setForgetPasswordError("New password and confirm password must be match");
                    setForgetButton('Reset Password');
                }
            }
        } else {
            setLoading(false);
            setForgetButton('Reset Password');
            setForgetPasswordError("Code and passwords are mandatory");
        }
    }

    const showNewPassword = () => {
        setforgotUser({ ...forgotUser, newPasswordShow: !forgotUser.newPasswordShow, errorMessage: "" });
    }
    const showNewComfirmPassword = () => {
        setforgotUser({ ...forgotUser, showNewComfirmPassword: !forgotUser.showNewComfirmPassword, errorMessage: "" });
    }


    const OnChangeEmail = event => {
        setforgotUser({ ...forgotUser, email: event.target.value, errorMessage: "" });
    }


    const OnChangeCode = event => {
        setforgotUser({ ...forgotUser, code: event.target.value, errorMessage: "" });
    }

    const OnChangeNewPassword = value => {
        const password =  value.value.trim();
        setforgotUser({ ...forgotUser, newPassword: password, passwordStrength: (value.errors.length == 0 ? true: false), errorMessage: "" });
    }

    const OnChangeconfirmNewPassword = event => {
        setforgotUser({ ...forgotUser, confirmNewPassword: event.target.value.trim(), errorMessage: "" });
    }

    return (
        <div>
            {!loading ? (
                <div className="full-screen-height container">
                    <div className="mobile-ui d-xl-none d-lg-none d-md-block d-block">
                        <div className="row">
                            <div className="col-12 mt-4 pt-3">
                                <img src={Tenant.logo} alt="" height="90px" />
                            </div>
                            <div className="col-12 mt-4 pt-3 pl-3 pr-3 signin-form-contents">
                                <div className="signin-form-mobile">
                                    <form className="form-signin" onSubmit={OnSubmit} autoComplete="true">

                                        <h4>Reset Password</h4>

                                        {!forgotUser.codeTextbox ? <h6 className="mt-4 mb-4 font-weight-bold sign-text">Please enter your email address</h6> : ""}

                                        {(!forgotUser.codeTextbox && !forgotUser.resetCompleted) ?
                                            <div className="custom-margin mb-4">
                                                <label className="sr-only">Email address</label>
                                                <input type="email" value={forgotUser.email} onChange={OnChangeEmail} className="form-control mb-3" autoComplete="on" placeholder="Email address" required />
                                            </div> : ""
                                        }
                                        {(forgotUser.codeTextbox && !forgotUser.resetCompleted) ?
                                            <div className="custom-margin mb-4">
                                                <label class="control-label text-left d-block">Verification</label>
                                                <span className="d-block p-3 form-hint">Account confirmation is required. please check your email for the verification code</span>
                                                <label className="sr-only">Verification Code</label>
                                                <input  autoComplete="off" type="number" value={forgotUser.code} onChange={OnChangeCode} className="form-control" placeholder="Verification Code" required />

                                                <div className="input-group mt-3">
                                                    <label className="sr-only">New Password</label>
                                                    <PasswordField className="form-control" fieldId="togglePassword" type={forgotUser.newPasswordShow ? 'text' : 'password'} label="New Password" placeholder="New password" onStateChanged={OnChangeNewPassword}  thresholdLength={5} minStrength={1} required />
                                                    <i onClick={showNewPassword} className={forgotUser.newPasswordShow ? 'fa fa-eye-slash forgetNewPasswordEye' : 'fa fa-eye forgetNewPasswordEye'} id="togglePassword"></i>
                                                </div>
                                                <div>
                                                    <label className="sr-only">Confirm Password</label>
                                                    <div className="input-group mb-3">
                                                        <input type={forgotUser.showNewComfirmPassword == true ? 'text' : 'password'} value={forgotUser.confirmNewPassword} onChange={OnChangeconfirmNewPassword} className="form-control" autoComplete="off" placeholder="Confirm password" required />
                                                        <i onClick={showNewComfirmPassword} className={forgotUser.showNewComfirmPassword ? 'fa fa-eye-slash showHidePassword' : 'fa fa-eye showHidePassword'} id="togglePassword"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            : ""
                                        }
                                        {forgotUser.resetCompleted ?

                                            <div className="container">
                                                <br></br>
                                                <br></br>
                                                <div className="alert alert-success reset-success" role="alert">
                                                    <i className="fas fa-check-circle"></i><p>Password has been reset successfully</p>
                                                </div>
                                                <br></br>
                                            </div>
                                            : ""
                                        }

                                        {!Validators.isEmptyOrNullOrUndefined(forgotUser.errorMessage) ?
                                            <div className="alert alert-danger custom-margin" role="alert login-error-message">
                                                {forgotUser.errorMessage}
                                            </div> : ""
                                        }

                                        <div className="row mt-3">
                                            <div className="col-12 custom-padding text-right">
                                                <label className="font-weight-bold forget-text " >
                                                    <Link to='login'>
                                                        <span className="forget-p">Back to Sign In <i className="fas fa-sign-in-alt"></i></span>
                                                    </Link>
                                                </label>
                                            </div>
                                        </div>
                                        {!forgotUser.resetCompleted ?
                                            <div className="row">
                                                <div className="col-12 custom-padding mt-4 mb-4">
                                                    <button className="btn btn-lg btn-primary btn-block login-btn btn-radius custom-btn-color-1 fs-18" type="submit">
                                                        {forgetButton}
                                                    </button>
                                                </div>
                                            </div>: ""

                                        }
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container col-md-6 login-container d-xl-block d-lg-block d-md-none d-none" >
                        <div className="row logo-row">
                            <div className="col-lg-12">
                                <img className="tenant-logo" src={Tenant.logo} alt="" height="90px" />
                            </div>
                        </div>
                        <form className="form-signin" onSubmit={OnSubmit} autoComplete="true">
                            <h4>Reset Password</h4>
                            {!forgotUser.codeTextbox ? <h6 className="mt-4 mb-4 font-weight-bold sign-text">Please enter your email address</h6> : ""}
                           
                            {(!forgotUser.codeTextbox && !forgotUser.resetCompleted) ?
                                <div>
                                    <label className="sr-only">Email address</label>
                                    <input type="email" value={forgotUser.email} onChange={OnChangeEmail} className="form-control mb-3" autoComplete="on" placeholder="Email address" required />
                                </div> : ""
                            }
                            {(forgotUser.codeTextbox && !forgotUser.resetCompleted) ?
                                <div>
                                     <br />
                                    <label class="control-label text-left d-block">Verification</label>
                                    <span className="d-block p-3  form-hint">Account confirmation is required. please check your email for the verification code</span>
                                    <label className="sr-only">Verification Code</label>
                                    <input  autoComplete="off" type="number" value={forgotUser.code} onChange={OnChangeCode} className="form-control" placeholder="Verification Code" required />
                                    <br />
                                    <div>
                                        <label className="sr-only">New Password</label>
                                        <PasswordField className="form-control" fieldId="togglePassword" type={forgotUser.newPasswordShow ? 'text' : 'password'} label="New Password" placeholder="New password" onStateChanged={OnChangeNewPassword}  thresholdLength={5} minStrength={1} required />
                                        <i onClick={showNewPassword} className={forgotUser.newPasswordShow ? 'fa fa-eye-slash forgetNewPasswordEye' : 'fa fa-eye forgetNewPasswordEye'} id="togglePassword"></i>
                                    </div>
                                    <div>
                                        <label className="sr-only">Confirm Password</label>
                                        <div className="input-group mb-3">
                                            <input type={forgotUser.showNewComfirmPassword == true ? 'text' : 'password'} value={forgotUser.confirmNewPassword} onChange={OnChangeconfirmNewPassword} className="form-control" autoComplete="off" placeholder="Confirm password" required />
                                            <i onClick={showNewComfirmPassword} className={forgotUser.showNewComfirmPassword ? 'fa fa-eye-slash showHidePassword' : 'fa fa-eye showHidePassword'} id="togglePassword"></i>
                                        </div>
                                    </div>
                                </div>
                                : ""
                            }
                            {forgotUser.resetCompleted ?

                                <div className="container">
                                    <br></br>
                                    <br></br>
                                    <div className="alert alert-success reset-success" role="alert">
                                        <i className="fas fa-check-circle"></i><p>Password has been reset successfully</p>
                                    </div>
                                    <br></br>
                                </div>
                                : ""
                            }

                            {!Validators.isEmptyOrNullOrUndefined(forgotUser.errorMessage) ?
                                <div className="alert alert-danger" role="alert login-error-message">
                                    {forgotUser.errorMessage}
                                </div> : ""
                            }

                            <div className="row mt-3">
                                <div className="col-lg-12  text-right">
                                    <label className="font-weight-bold forget-text " >
                                        <Link to='login'>
                                            <span className="forget-p">Back to Sign In <i className="fas fa-sign-in-alt"></i></span>
                                        </Link>
                                    </label>
                                </div>
                            </div>
                            {!forgotUser.resetCompleted ?
                                <button className="btn btn-lg btn-primary btn-block login-btn btn-radius custom-btn-color-1 fs-18 mt-3" type="submit">
                                    {forgetButton}
                                </button> : ""

                            }

                        </form>
                    </div>
                </div>) : <LoadingPage />}
        </div>
    );
}

export default ForgetPassword;
