import JwtDecode from 'jwt-decode';
import Cookies from 'js-cookie';
import platform from "platform";
import { v4 as uuidv4 } from 'uuid';
class JWTService {
    
    basePath = null;
    constructor() {
        this.domain = process.env.REACT_APP_DOMAIN;
        this.basePathName = 'bPath';
        this.redirectTo = "redirectTo-"+process.env.REACT_APP_ENV;
    }

    getAccessTokenName(){
        return "accessToken-"+this.getBasePath()+"-"+ process.env.REACT_APP_ENV;
    }
    
    getRefreshTokenName(){
        return "refreshToken-"+this.getBasePath()+"-"+ process.env.REACT_APP_ENV;
    }
    
    getRedirectToName(){
        return "redirectTo-"+this.getBasePath()+"-"+ process.env.REACT_APP_ENV;
    }

    getClientIdName() {
        return "id-"+this.getBasePath()+"-"+ process.env.REACT_APP_ENV;
    }

    getDeviceKeyName(){
        return "dkey"
    }

    getDeviceIdName(){
        return "did";
    }

    getDeviceInfoName(){
        return "dinfo";
    }

    setBasePath(basePath){
        this.basePath = basePath;
        Cookies.set(this.basePathName, basePath, { expires: 365, domain: this.domain, path: '/' });
        this.setDeviceInfo();
    }

    setDeviceId(id) {
        Cookies.set(this.getDeviceIdName(), id, { expires: 365, domain: this.domain, path: '/' });
    }

    getDeviceId() {
        return Cookies.get(this.getDeviceIdName());
    }

    getDeviceInfo() {
        var id = Cookies.get(this.getDeviceInfoName());
        if(!id){
            return this.setDeviceInfo();
        }else{
            return id;
        }
    }


    getDeviceKey() {
        var id = Cookies.get(this.getDeviceKeyName());
        if(!id){
            return "unknown"
        }else{
            return id;
        }
    }

    getBasePath(){
        if(this.basePath){
            return this.basePath;
        }else{
            return  Cookies.get(this.basePathName);
        }
    }
    
    setAccessToken(token,clientId) {
        try {
            const accessTokenName = this.getAccessTokenName();
            const clientIdName = this.getClientIdName();
            //console.log("token name :"+ accessTokenName);
            Cookies.set(accessTokenName, token, { expires: 1, path: "/",  domain: this.domain });
            Cookies.set(clientIdName, clientId, { expires: 1, path: "/", domain: this.domain });
            return true;
        } catch (err) {
            //console.log('setAccessToken -> err', err);
            return false;
        }
    }

    setClientId(clientId) {
        try {
            const clientIdName = this.getClientIdName();
            console.log('setClientId', clientId);
            Cookies.set(clientIdName, clientId, { expires: 1, path: "/", domain: this.domain });
            return true;
        } catch (err) {
            console.log('setClientId', err);
            return false;
        }
    }

    setRefreshToken(token, deviceKey) {
        try {
            const refreshTokenName = this.getRefreshTokenName();
            // const deviceKeyName = this.getDeviceKeyName();
            // Cookies.set(deviceKeyName, deviceKey, { domain: this.domain });
            Cookies.set(refreshTokenName, token, { expires: 30, path: "/", domain: this.domain });
            this.setDeviceInfo();
            return true;
        } catch (err) {
            //console.log('setAccessToken -> err', err);
            return false;
        }
    }


    isAccessTokenExpired() {
        try {
            const accessTokenName = this.getAccessTokenName();
            //console.log("token name :"+ accessTokenName);
            var token = Cookies.get(accessTokenName)
            if (token) {
                var decodedToken = JwtDecode(token);
                //console.log(decodedToken);
                if (decodedToken.exp < (new Date().getTime() + 1) / 1000) {
                    return true;
                } else {
                    return false;
                }
            }
        } catch (err) {
            //console.log('getAccessToken -> err', err);
            return true;
        }
    }

    getRedirectUrl() {
        try {
            var token = Cookies.get(this.redirectTo);
            if (token) {
                return token
            } else {
                return null;
            }
        } catch (ex) {
            //console.log(ex);
        }
    }
    removeRedirectUrl() {
        try {
            Cookies.remove(this.redirectTo, { domain: this.domain });
        } catch (ex) {
            //console.log(ex);
        }
    }

    getAccessToken() {
        try {
            const accessTokenName = this.getAccessTokenName();
            //console.log("token name :"+ accessTokenName);
            var token = Cookies.get(accessTokenName)
            //console.log(token)
            if (token) {
                const decodedToken = JwtDecode(token);
                //console.log(decodedToken);
                if (decodedToken.exp < (new Date().getTime() + 1) / 1000) {
                    return null;
                } else {
                    return token;
                }
            }else{
                return null;
            }
        } catch (err) {
            //console.log('getAccessToken -> err', err);
            return null;
        }
    }

    getRefreshToken() {
        try {
            const refreshTokenName = this.getRefreshTokenName();
            //console.log("token name :"+ refreshTokenName);
            var token = Cookies.get(refreshTokenName)
            if (token) {
                return token;
            } else {
                return null;
            }
        } catch (err) {
            //console.log('getAccessToken -> err', err);
            return null;
        }
    }

    getClientId() {
        try {
            const clientIdName = this.getClientIdName();
            //console.log("client name :"+ clientIdName);
            var token = Cookies.get(clientIdName);
            if (token) {
                return token;
            } else {
                return null;
            }
        } catch (err) {
            //console.log('getClientId -> err', err);
            return null;
        }
    }

    removeAccessToken() {
        try {
            const accessTokenName = this.getAccessTokenName();
            const clientIdName = this.getClientIdName();
            //console.log("token name :"+ accessTokenName);
            Cookies.remove(accessTokenName, { domain: this.domain });
            Cookies.remove(clientIdName, { domain: this.domain });
            return true;
        } catch (err) {
            //console.log('removeAccessToken -> err', err);
            return false;
        }
    }

    removeRefreshToken() {
        try {
           // console.log("remove :"+ refreshTokenName);
            const refreshTokenName = this.getRefreshTokenName();
            const clientIdName = this.getClientIdName();
            // const deviceKeyName = this.getDeviceKeyName();
            // Cookies.remove(deviceKeyName, { domain: this.domain });
            Cookies.remove(refreshTokenName, { domain: this.domain });
            Cookies.remove(clientIdName, { domain: this.domain });
            return true;
        } catch (err) {
            //console.log('removeRefreshToken -> err', err);
            return false;
        }
    }

    removeTokens() {
        try {
            const accessTokenName = this.getAccessTokenName();
            const refreshTokenName = this.getRefreshTokenName();
            const clientIdName = this.getClientIdName();
            // const deviceKeyName = this.getDeviceKeyName();
            // Cookies.remove(deviceKeyName, { domain: this.domain });
            Cookies.remove(refreshTokenName, { domain: this.domain });
            Cookies.remove(accessTokenName, { domain: this.domain });
            Cookies.remove(clientIdName, { domain: this.domain });
            return true;
        } catch (err) {
            //console.log('removeTokens -> err', err);
            return false;
        }
    }

    getHistory() {
        try {
            var sug = localStorage.getItem('99tick-history');
            //console.log(sug);
            try {
                const array = JSON.parse(sug);
                return array;
            } catch (ex) {
                //console.log(ex);
            }
        } catch (err) {
            //console.log('getAccessToken -> err', err);
            return null;
        }
    }

    setHistory(data) {
        try {
            const sug = Cookies.get('99tick-history');
            //console.log(sug);
            const tArray = [{ name: data.basePath, url: data.basePath, logo: data.logo }];
            
            localStorage.setItem('99tick-history', JSON.stringify(tArray));
        } catch (ex) {
            //console.log(ex);
        }
    }

    setDeviceInfo() {
        try {
            console.log(platform);
            var id = this.getDeviceId();
            //id unique idenfier
            var newId = uuidv4();
            if(!id){
                id = newId;
                this.setDeviceId(newId) 
            }
            //key used for cognito
            var deviceKey = this.getDeviceKey();
            deviceKey = deviceKey == null ? 'unknown' : deviceKey;

            var location = "unknown_unknown"
            var deviceId =  `${id}_${platform.name}_${platform.product}_${this.getDeviceType()}_${location}_${deviceKey}`;
            if (!navigator.geolocation) {
                console.log('Geolocation is not supported by your browser');
                Cookies.set(this.getDeviceInfoName(), btoa(deviceId), { expires: 1, domain: this.domain, path: '/' });
            } else {
              navigator.geolocation.getCurrentPosition((position) => {
                location = `${position.coords.latitude}_${position.coords.longitude}`;
                deviceId =  `${id}_${platform.name}_${platform.product}_${this.getDeviceType()}_${location}_${deviceKey}`;
                console.log('Geolocation enabled');
                Cookies.set(this.getDeviceInfoName(), btoa(deviceId), { expires: 1, domain: this.domain, path: '/' });
              }, () => {
                Cookies.set(this.getDeviceInfoName(), btoa(deviceId), { expires: 1, domain: this.domain, path: '/' });
                console.log('Unable to retrieve your location');
              });
            }
            return btoa(deviceId);
        } catch (ex) {
            console.log(ex);
            return null;
        }
    }

    getDeviceType() {
        let userAgent = window.navigator.userAgent.toLowerCase(),
        macosPlatforms = /(macintosh|macintel|macppc|mac68k|macos)/i,
        windowsPlatforms = /(win32|win64|windows|wince)/i,
        iosPlatforms = /(iphone|ipad|ipod)/i,
        os = null;
    
      if (macosPlatforms.test(userAgent)) {
        os = "Macos";
      } else if (iosPlatforms.test(userAgent)) {
        os = "Ios";
      } else if (windowsPlatforms.test(userAgent)) {
        os = "Windows";
      } else if (/android/.test(userAgent)) {
        os = "Android";
      } else if (!os && /linux/.test(userAgent)) {
        os = "Linux";
      }
      return os;
    }
}

export default new JWTService();