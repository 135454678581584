import Error404 from './public-components/error-pages/Error404';
import { UserProvider } from './protected-components/contexts/UserContext';
import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ConfigurationService from './shared/services/configuration-services/configurationService';
import LoadingPage from './shared/components/LoadingPage';
import TenantContext from './protected-components/contexts/TenantCotext';
import Main from './Main';
import jwtService from './shared/services/jwt-services/jwt-service';

function App(props) {

  const location = useLocation();

  // console.log(location.pathname);
  // console.log(props.location);

  const basePath = location.pathname;

  const [Tenant, setTenant] = useContext(TenantContext);
  const [TenantConfig, setTenantConfig] = useState({
    loading: true,
    showPages: null,
    invalidTenant: false,
    basePath : basePath
  });

  const invalidTenant = () => {
    setTenantConfig({ loading: false, showPages: false, invalidTenant: true,  basePath : basePath  });
    setTenant({ status: false, showPages: false, basePath: null, colors: null, clientId: null ,tenantName: null});
  }

  const validTenant = (data) => {
    //console.log(data);
    //console.log(Tenant.basePath, location.pathname);
    setTenant({ 
      status: true, 
      showPages: true,
      basePath: data.basePath,
      tenantName: data.tenantName,
      clientId: data.clientId, 
      colors : data.colors, 
      logo : data.logo,
      favIcon: data.favIcon
    });
    setTenantConfig({ 
      loading: false, 
      showPages: (<Main basePath={data.basePath} />),
      invalidTenant: false,
      basePath : data.basePath, 
      colors : data.colors, 
      logo : data.logo,
      favIcon: data.favIcon
    });
    setFavIcon(data.favIcon);
    jwtService.setHistory(data);
  }

  const setFavIcon = (url) => {
    if(url){
      var link = document.querySelector("link[rel~='icon']");
      if (!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        document.getElementsByTagName('head')[0].appendChild(link);
      }
      link.href = url;
    }
  }


  useEffect(() => {
    //console.log(location.pathname);
    if (Tenant.status && Tenant.basePath !== null && Tenant.showPages === true) {
      setTenantConfig({ loading: false, showPages: true, invalidTenant: false,  basePath : basePath });
      jwtService.setBasePath(Tenant.basePath);
    } else {
      if (location.pathname !== '') {
        const tenantName = basePath.split('/')[1];
        if (tenantName) {
          ConfigurationService.getTenantConfig(tenantName).then(data => {
            //console.log(data.data)
            const resConfig = data.data.data;
            if (data.data && data.data.messageKey !== "TENANT_NOT_FOUND") {
              jwtService.setBasePath(tenantName);
              jwtService.setClientId(resConfig.clientId);
              validTenant({ 
                basePath: resConfig.basePath,
                clientId: resConfig.clientId,
                favIcon: resConfig.favIcon,
                tenantName: resConfig.tenantName,   
                colors : resConfig.colors, 
                logo : resConfig.logo 
              });
            } else {
              invalidTenant();
            }
          }).catch(err => {
            //console.log(err)
            invalidTenant();
          });
        } else {
          invalidTenant();
        }
      } else {
        invalidTenant();
      }
    }
  }, []);



  return (
    <UserProvider>
      <div className="App">
        {TenantConfig.loading ? <LoadingPage /> : null }
        {TenantConfig.invalidTenant ? <Error404 /> : null }
        { TenantConfig.showPages  }
      </div>
    </UserProvider>
  );
}

export default App;
