import jwtService from '../jwt-services/jwt-service';
import axios from 'axios';
import refreshTokenService from './refresh-token-service';
var isAuthProgress = false;
class ApiService {
 
  constructor() {
    axios.interceptors.response.use((response) => {
      //console.log('success intercepter');
      return response;
    }, function (error) {
      const originalRequest = error.config;
      if (error.response.status === 401 && !isAuthProgress) {
        console.log('refresh token intercepter ' + error.response.status);
        originalRequest._retry = true;
        isAuthProgress = true;
        refreshTokenService.refreeshToken().then(accessToken => {
          isAuthProgress = false;
          if (accessToken) {
            console.log('received access token ' + accessToken);
            originalRequest.headers['Authorization'] = `Bearer ${accessToken}`;
            console.log('new header ' +  originalRequest.headers['Authorization']);
            return axios(originalRequest);
          } else {
            console.log('intercepter unsuccess');
            throw error;
          }
        });
      }else{
        throw error;
      }
    });
  }

  setHeaders() {
    const headersConfig = {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    };
    var token = jwtService.getAccessToken();
    if (token) {
      headersConfig.headers['Authorization'] = `Bearer ${token}`;
    }
    return headersConfig;
  }

  // Perform a GET Request
  async get(path) {
    return axios.get(`${path}`, this.setHeaders());
  }

  // Perform a post Request
  async post(path, data) {
    return axios.post(`${path}`, data, this.setHeaders());
  }


  // Perform a put Request
  async put(path, data) {
    return axios.put(`${path}`, data, this.setHeaders())
      .then(function (response) {
        //console.log(response);
      })
      .catch(function (error) {
        //console.log(error);
      });
  }

  // Perform a put Request
  async delete(path, data) {
    return axios.delete(`${path}`, data, this.setHeaders())
      .then(function (response) {
        //console.log(response);
      })
      .catch(function (error) {
        //console.log(error);
      });
  }
}
export default new ApiService();
