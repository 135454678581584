
import { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import '../App.scss';
import jwtService from '../shared/services/jwt-services/jwt-service';
import UserContext from './contexts/UserContext';
import SideBar from './side-bar/SideBar';
import Nav from './nav-bar/Nav'
import UserService from '../shared/services/user-services/userService';
import LoadingPage from '../shared/components/LoadingPage';
import apiService from '../shared/services/api-services/api-service';
import TenantContext from './contexts/TenantCotext';
import Development from '../public-components/error-pages/Development';

function Home(props) {

    let history = useHistory();
    let location = useLocation();
    const login =`/${props.basePath}/login`; 

    const [Tenant, setTenant] = useContext(TenantContext);

    const [User, setUser] = useContext(UserContext);
    const [isLoading, setIsLoading] = useState(true);

    // should be removed
    const [image, setImage] = useState(null);
    const [dynamicStyle,setDynamicStyle] = useState('#FFFFFF')

    const classPortal = process.env.REACT_APP_TUTER_URL + Tenant.basePath + "/home"
    const studentPortal = process.env.REACT_APP_STUDENT_URL + Tenant.basePath + "/home";

    const logout = () => {
        setUser(null);
        jwtService.removeAccessToken();
        if(location.pathname !== login) {
            //console.log(login);
            history.push(login);
        }
    }

    const getUser = () => {
        //console.log(User);
        if(jwtService.getAccessToken() && User == null) {
            UserService.getUser().then( user =>{
                //console.log(user);
                if(user.userRole === 'Teacher') {
                    window.location.href = classPortal;
                }else if(user.userRole === 'Student') {
                    window.location.href = studentPortal;
                }else{
                    setUser(user);
                    setIsLoading(false);
                }
            }).catch(err=> {
                //console.log(err);
                setIsLoading(false);
                logout();
            });
        }else{
            setIsLoading(false);
        }
    }

    const getImage = () => {
        apiService.get('umm/images/image.jpg').then(data =>{
            const res = data.data.data;
            setImage(res.url);
        }).catch( err=>{
            //console.log(err);
        });
    }

    useEffect(() => {
        getUser();
        if(Tenant.colors && Tenant.colors.background){
            setDynamicStyle(Tenant.colors.background);
        }
           
    },[]);   
    
    //console.log(User);
    //console.log(props.basePath);
    
    return (
        <>
            { isLoading ? <LoadingPage/> :
            (<>
                    {/* {JSON.stringify(User)} */}
                    <Nav/>
                    <SideBar/>
                    {/*<div  style={{backgroundColor : dynamicStyle}}><h2>Home</h2></div>*/}
                    <Development/>
                    {/* <button className="btn btn-primary" onClick={getImage} >Get Image</button> */}   
                    { image !== null ? <img className="img-responsive img-rounded" src={image} alt="User picture"/> : '' }
                        
            </>
            )}
        </>
    );
}

export default Home;
